import React from 'react'
import marked from 'marked'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Feature as FeatureType } from '../typings'
import { Video } from './Video'

interface FeatureProps {
  feature: FeatureType
}

export const Feature = ({ feature }: FeatureProps) => {
  const image = getImage(feature.image?.localFile)
  const embedUrl = feature.embedUrl

  return (
    <div className="flex flex-col md:flex-row md:even:flex-row-reverse gap-4 md:gap-8 lg:gap-16">
      {embedUrl && (
        <Video className="md:w-1/2 md:max-w-[450px] lg:flex-shrink-0 rounded-md shadow-md overflow-hidden" embedUrl={embedUrl} />
      )}
      {image && !embedUrl && (
        <GatsbyImage className="md:w-1/2 lg:w-auto lg:flex-shrink-0 rounded-md shadow-md" image={image} alt={feature.title} />
      )}
      {!image && !embedUrl && <div className="hidden md:block md:w-1/2 lg:w-112 lg:flex-shrink-0" />}
      <div className="md:w-1/2 lg:w-auto md:pt-4">
        <h2 className="text-2xl lg:text-3xl font-bold mb-2 lg:mb-4">{feature.title}</h2>
        <div className="markdown text-gray-600 leading-snug" dangerouslySetInnerHTML={{ __html: marked(feature.description) }} />
      </div>
    </div>
  )
}

export const MainFeature = ({ feature }: FeatureProps) => {
  const image = getImage(feature.image?.localFile)
  const embedUrl = feature.embedUrl

  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-8 lg:gap-16">
      {embedUrl && <Video className="md:w-1/2 lg:w-128 lg:flex-shrink-0 rounded-md shadow-lg overflow-hidden" embedUrl={embedUrl} />}
      {image && !embedUrl && (
        <GatsbyImage className="md:w-1/2 lg:h-96 lg:w-128 lg:flex-shrink-0 rounded-md shadow-lg" image={image} alt={feature.title} />
      )}
      {!image && !embedUrl && <div className="md:w-1/2 lg:h-96 lg:w-128 lg:flex-shrink-0 rounded-md bg-white z-10" />}
      <div className="md:w-1/2 flex flex-col md:justify-end md:mb-4">
        <h2 className="text-2xl lg:text-3xl text-center md:text-left font-bold lg:mb-4">{feature.title}</h2>
        <p className="lg:text-lg text-center md:text-left text-gray-600 leading-snug">{feature.description}</p>
      </div>
    </div>
  )
}
