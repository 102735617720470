import React from 'react'
import { Helmet } from 'react-helmet'
import { PageProps, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { transparentize } from 'polished'
import { Product } from '../typings'
import { Layout } from '../components/Layout'
import { Hero } from '../components/Hero'
import { Buttons } from '../components/Button'
import { Feature, MainFeature } from '../components/Feature'
import { Banner } from '../components/Banner'

interface ProjectDetailPageProps {
  strapiProduct: Omit<Product, 'guide'>
}

const ProjectDetailPage = ({ data }: PageProps<ProjectDetailPageProps>) => {
  const product = data.strapiProduct
  const logo = getImage(product.logo?.localFile)
  const mainFeature = product.features[0]
  const features = [...product.features?.slice(1)]
  const lightenColor = product.color && transparentize(0.9, product.color)

  return (
    <Layout>
      <Helmet titleTemplate="meinAPOVERLAG - %s">
        <meta name="description" content={product.hero.description} />
        <title>{product.name}</title>
      </Helmet>
      <Hero bgColor={lightenColor}>
        <div className="max-w-xl flex flex-col items-center">
          {logo && <GatsbyImage className="mb-8 flex-shrink-0" image={logo} alt={product.name} />}
          <h1 className="text-2xl lg:text-3xl text-center font-bold mb-4">{product.hero.title}</h1>
          <p className="text-lg lg:text-xl text-center">{product.hero.description}</p>
          {product.hero.buttons?.length && (
            <div className="flex flex-col md:flex-row md:justify-center md:flex-wrap gap-2 md:gap-4 mt-8">
              <Buttons buttons={product.hero.buttons} className="sm:w-60" />
            </div>
          )}
        </div>
      </Hero>
      {mainFeature && (
        <div className="-mt-12 mb-8">
          <MainFeature feature={mainFeature} />
        </div>
      )}
      {product.quote && (
        <div className="px-4 py-8">
          <h3 className="text-2xl md:text-3xl font-bold text-center">{product.quote}</h3>
        </div>
      )}
      {features.length && (
        <div>
          <div style={{ backgroundColor: lightenColor }} className="full-width h-80 bg-gradient-to-t from-gray-50" />
          <div className="relative -mt-80 py-8 sm:py-12 md:py-16 lg:py-20 space-y-12 md:space-y-16 lg:space-y-20">
            {features.map((feature, index) => (
              <Feature key={feature.id} feature={feature} />
            ))}
          </div>
        </div>
      )}
      {product.banner && <Banner banner={product.banner} bgColor={lightenColor} />}
    </Layout>
  )
}

export default ProjectDetailPage

export const query = graphql`
  query ($slug: String!) {
    strapiProduct(slug: { eq: $slug }) {
      slug
      name
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData(height: 64, placeholder: NONE)
          }
        }
      }
      color
      quote
      hero {
        title
        description
        buttons {
          id
          text
          url
          type
        }
      }
      features {
        id
        title
        description
        embedUrl
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 450, aspectRatio: 1.5)
            }
          }
        }
      }
      banner {
        title
        description
        buttons {
          id
          text
          url
          type
        }
      }
    }
  }
`
