import React from 'react'

interface VideoProps {
  embedUrl: string
  className?: string
}

export const Video = ({ embedUrl, className }: VideoProps) => {
  return (
    <div className={className}>
      <div className="relative pb-[56.26%] w-full">
        <iframe
          className="absolute top-0 left-0"
          width="100%"
          height="100%"
          src={addQueryParams(embedUrl)}
          title="APOVERLAG Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

const addQueryParams = (urlString: string) => {
  const url = new URL(urlString)
  const searchParams = url.searchParams

  searchParams.set('modestbranding', '1')
  searchParams.set('rel', '0')

  url.search = searchParams.toString()

  return url.toString()
}
